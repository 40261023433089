var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"detail-title"},[_c('el-breadcrumb',{attrs:{"separator":"/"}},[_c('el-breadcrumb-item',[_vm._v("运营与营销")]),_c('el-breadcrumb-item',{attrs:{"to":{ path: '/aimActivity' }}},[_vm._v("限时活动")]),_c('el-breadcrumb-item',[_vm._v("新增")])],1)],1),_c('div',{staticClass:"detail-info margin-l-sm"},[_c('el-form',{ref:"form",attrs:{"model":_vm.form,"label-width":"120px"}},[_c('el-form-item',{staticClass:"form_input",attrs:{"label":"活动名称","prop":"name","rules":[
                { required: true, message: '请输入活动名称', trigger: 'blur' },
            ]}},[_c('el-input',{model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('el-form-item',{attrs:{"label":"封面","prop":"cover_img","rules":[
                { required: true, message: '请输入活动封面', trigger: 'change' },
            ]}},[_c('cover',{attrs:{"uploadPath":_vm.uploadPath},on:{"success":_vm.uploadSuccess}})],1),_c('el-form-item',{attrs:{"label":"活动介绍","prop":"introduce","rules":[
                { required: true, message: '请输入活动介绍', trigger: 'blur' },
            ]}},[_c('editor',{attrs:{"content":_vm.form.introduce},on:{"onChange":_vm.onChange}})],1),_c('el-form-item',{attrs:{"label":"开始时间","prop":"start_at","rules":[
                { required: true, message: '请选择开始时间', trigger: 'change' },
            ]}},[_c('el-date-picker',{attrs:{"type":"datetime","placeholder":"选择开始时间","value-format":"yyyy-MM-dd HH:mm:ss"},model:{value:(_vm.form.start_at),callback:function ($$v) {_vm.$set(_vm.form, "start_at", $$v)},expression:"form.start_at"}})],1),_c('el-form-item',{attrs:{"label":"结束时间","prop":"end_at","rules":[
                { required: true, message: '请选择结束时间', trigger: 'change' },
            ]}},[_c('el-date-picker',{attrs:{"type":"datetime","placeholder":"选择结束时间","value-format":"yyyy-MM-dd HH:mm:ss"},model:{value:(_vm.form.end_at),callback:function ($$v) {_vm.$set(_vm.form, "end_at", $$v)},expression:"form.end_at"}})],1),_c('el-form-item',{attrs:{"label":"应签到天数","prop":"sign_days","rules":[
                { required: true, message: '应签到天数', trigger: 'change' },
            ]}},[_c('el-input-number',{attrs:{"min":1,"size":"small"},model:{value:(_vm.form.sign_days),callback:function ($$v) {_vm.$set(_vm.form, "sign_days", $$v)},expression:"form.sign_days"}})],1),_c('el-form-item',{attrs:{"label":"奖励积分","prop":"reward_integral","rules":[
                { required: true, message: '奖励积分', trigger: 'change' },
            ]}},[_c('el-input-number',{attrs:{"min":0,"size":"small"},model:{value:(_vm.form.reward_integral),callback:function ($$v) {_vm.$set(_vm.form, "reward_integral", $$v)},expression:"form.reward_integral"}})],1),_c('el-form-item',{attrs:{"label":"状态 ","prop":"reward_integral","rules":[
                { required: true, message: '状态 ', trigger: 'change' },
            ]}},[_c('el-switch',{attrs:{"active-color":"#13ce66","inactive-color":"#ff4949"},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}})],1),_c('el-form-item',[_c('el-button',{attrs:{"size":"small","type":"primary","loading":_vm.loading},on:{"click":_vm.onSubmit}},[_vm._v("保存")])],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }